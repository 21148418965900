@import url("https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap");

html {
  box-sizing: border-box;
  font-size: var(--iress-global-font-size, 0.875rem);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: var(--iress-global-font-family, sans-serif);
  color: var(--iress-global-text-color, #333);
  font-weight: var(--iress-global-font-weight, normal);
  background-color: var(--iress-global-background-color, #fff);
  line-height: var(--iress-global-line-height, 1.5);
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
dl,
blockquote {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: var(--iress-global-heading-color, #333);
  font-family: var(--iress-global-heading-font-family, sans-serif);
  font-weight: var(--iress-global-heading-font-weight, bold);
  margin-top: var(--iress-global-heading-margin-top, 1rem);
  margin-bottom: var(--iress-global-heading-margin-bottom, 1rem);
}

h1,
.h1 {
  font-size: var(--iress-global-h1-font-size, 3rem);
  font-weight: var(--iress-global-h1-font-weight, 400);
}

h2,
.h2 {
  font-size: var(--iress-global-h2-font-size, 2rem);
}

h3,
.h3 {
  font-size: var(--iress-global-h3-font-size, 1.66rem);
}

h4,
.h4 {
  font-size: var(--iress-global-h4-font-size, 1.33rem);
}

h5,
.h5 {
  font-size: var(--iress-global-h5-font-size, 1rem);
  font-weight: var(--iress-font-weight-base-bold);
}

h6,
.h6 {
  font-size: var(--iress-global-h5-font-size, 1rem);
  font-weight: var(--iress-font-weight-base-regular);
  font-style: italic;
}

h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.h6:first-child {
  margin-top: 0;
}

.display-1,
.display-2,
.display-3 {
  font-family: var(--iress-global-display-font-family, sans-serif);
  font-weight: var(--iress-global-display-font-weight, normal);
  line-height: var(--iress-global-display-line-height, 1.25);
  color: var(--iress-global-display-color, #333);
  margin-top: var(--iress-global-display-margin-top, 0);
  margin-bottom: var(--iress-global-display-margin-bottom, 0);
}

.display-1 {
  font-size: var(--iress-global-display1-font-size, 3.75rem);
}

.display-2 {
  font-size: var(--iress-global-display2-font-size, 2.75rem);
}

.display-3 {
  font-size: var(--iress-global-display3-font-size, 2.25rem);
}

.lead {
  font-weight: var(--iress-global-lead-font-weight, normal);
  font-size: var(--iress-global-lead-font-size, 1.5rem);
  color: var(--iress-global-lead-color, #333);
}

.text-success {
  color: var(--iress-global-success, #390);
}

.text-warning {
  color: var(--iress-global-warning, #c63);
}

.text-danger {
  color: var(--iress-global-danger, #f00);
}

caption,
.caption,
cite {
  font-weight: var(--iress-global-caption-font-weight, normal);
  font-size: var(--iress-global-caption-font-size, 0.875rem);
  color: var(--iress-global-caption-color, #666);
}

a {
  color: var(--iress-global-link-color);
  text-decoration: var(--iress-global-link-text-decoration);
  font-weight: var(--iress-global-link-font-weight);
}

a:hover,
a:focus {
  color: var(--iress-global-link-color-hover);
  text-decoration: var(--iress-global-link-text-decoration-hover);
}
a:focus {
  outline: none;
  box-shadow: var(--iress-global-focus);
}
a:active,
.link:active {
  color: var(--iress-global-link-color-active);
  text-decoration: var(--iress-global-link-text-decoration-active);
}

code {
  display: inline-block;
  font-family: var(--iress-global-code-font-family);
  font-size: var(--iress-global-code-font-size);
  color: var(--iress-global-code-color);
  background-color: var(--iress-global-code-background-color);
  border: var(--iress-global-code-border);
  border-color: var(--iress-global-code-border-color);
  line-height: var(--iress-global-code-line-height);
  padding: 1px;
}

pre {
  margin-top: 0;
}

blockquote {
  position: relative;
  padding-left: var(--iress-spacing-xl);
  margin-left: 0;
  font-style: italic;
}

blockquote::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: var(--iress-spacing-xxs);
  background-color: var(--iress-color-purple);
}

pre > code {
  position: relative;
  display: block;
  overflow: auto;
  padding: var(--iress-spacing-md) var(--iress-spacing-md)
    var(--iress-spacing-md) var(--iress-spacing-xl);
  border: var(--iress-global-code-border);
  border-color: transparent;
}

hr {
  border: 1px solid var(--iress-border-color--standard);
}

.iress-stack,
.iress-stack > *,
.iress-stack > [data-wrapper-children] > * {
  display: block !important;
  margin-top: 0 !important;
}

.iress-stack > *:not(.iress-inline),
.iress-stack > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0 !important;
}

.iress-stack > *:not(.iress-inline):last-child,
.iress-stack > [data-wrapper-children] > *:not(.iress-inline):last-child {
  margin-bottom: 0 !important;
}

.iress-stack--none > *:not(.iress-inline),
.iress-stack--none > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0 !important;
}

.iress-stack--xs > *:not(.iress-inline),
.iress-stack--xs > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
}

.iress-stack--sm > *:not(.iress-inline),
.iress-stack--sm > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
}

.iress-stack--md > *:not(.iress-inline),
.iress-stack--md > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
}

.iress-stack--lg > *:not(.iress-inline),
.iress-stack--lg > [data-wrapper-children] *:not(.iress-inline) {
  margin-bottom: var(--iress-spacing-md, 1rem) !important;
}

.iress-stack--xl > *:not(.iress-inline),
.iress-stack--xl > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
}

@media only screen and (min-width: 576px) {
  .iress-stack-sm--none > *:not(.iress-inline),
  .iress-stack-sm--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-sm--xs > *:not(.iress-inline),
  .iress-stack-sm--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-sm--sm > *:not(.iress-inline),
  .iress-stack-sm--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-sm--md > *:not(.iress-inline),
  .iress-stack-sm--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-sm--lg > *:not(.iress-inline),
  .iress-stack-sm--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-sm--xl > *:not(.iress-inline),
  .iress-stack-sm--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 768px) {
  .iress-stack-md--none > *:not(.iress-inline),
  .iress-stack-md--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-md--xs > *:not(.iress-inline),
  .iress-stack-md--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-md--sm > *:not(.iress-inline),
  .iress-stack-md--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-md--md > *:not(.iress-inline),
  .iress-stack-md--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-md--lg > *:not(.iress-inline),
  .iress-stack-md--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-md--xl > *:not(.iress-inline),
  .iress-stack-md--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .iress-stack-lg--none > *:not(.iress-inline),
  .iress-stack-lg--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-lg--xs > *:not(.iress-inline),
  .iress-stack-lg--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-lg--sm > *:not(.iress-inline),
  .iress-stack-lg--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-lg--md > *:not(.iress-inline),
  .iress-stack-lg--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-lg--lg > *:not(.iress-inline),
  .iress-stack-lg--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-lg--xl > *:not(.iress-inline),
  .iress-stack-lg--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .iress-stack-xl--none > *:not(.iress-inline),
  .iress-stack-xl--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-xl--xs > *:not(.iress-inline),
  .iress-stack-xl--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-xl--sm > *:not(.iress-inline),
  .iress-stack-xl--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-xl--md > *:not(.iress-inline),
  .iress-stack-xl--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-xl--lg > *:not(.iress-inline),
  .iress-stack-xl--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-xl--xl > *:not(.iress-inline),
  .iress-stack-xl--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

.iress-inline {
  display: flex;
  flex-wrap: wrap;
}

.iress-inline--left {
  justify-content: flex-start;
}

.iress-inline--right {
  justify-content: flex-end;
}

.iress-inline--center {
  justify-content: center;
}

.iress-inline > * {
  margin-top: 0 !important;
  display: inline-block !important;
}

.iress-inline--left.iress-inline--none > *,
.iress-inline--center.iress-inline--none > *,
.iress-inline--right.iress-inline--none > * {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.iress-inline--xs {
  margin-bottom: var(--iress-spacing-neg-xxs);
}

.iress-inline--xs.iress-inline--left > * {
  margin-right: var(--iress-spacing-xxs) !important;
  margin-left: 0 !important;
}

.iress-inline--xs.iress-inline--right > * {
  margin-left: var(--iress-spacing-xxs) !important;
  margin-right: 0 !important;
}

.iress-inline--xs.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
  margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
}

.iress-inline--xs > * {
  margin-bottom: var(--iress-spacing-xxs) !important;
}

.iress-inline--sm {
  margin-bottom: var(--iress-spacing-neg-xs);
}

.iress-inline--sm > * {
  margin-bottom: var(--iress-spacing-xs) !important;
}

.iress-inline--sm.iress-inline--left > * {
  margin-right: var(--iress-spacing-xs) !important;
  margin-left: 0 !important;
}

.iress-inline--sm.iress-inline--right > * {
  margin-left: var(--iress-spacing-xs) !important;
  margin-right: 0 !important;
}

.iress-inline--sm.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-xs) / 2) !important;
  margin-right: calc(var(--iress-spacing-xs) / 2) !important;
}

.iress-inline--md {
  margin-bottom: var(--iress-spacing-neg-sm);
}

.iress-inline--md > * {
  margin-bottom: var(--iress-spacing-sm) !important;
}

.iress-inline--md.iress-inline--left > * {
  margin-right: var(--iress-spacing-sm) !important;
  margin-left: 0 !important;
}

.iress-inline--md.iress-inline--right > * {
  margin-left: var(--iress-spacing-sm) !important;
  margin-right: 0 !important;
}

.iress-inline--md.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-sm) / 2) !important;
  margin-right: calc(var(--iress-spacing-sm) / 2) !important;
}

.iress-inline--lg {
  margin-bottom: var(--iress-spacing-neg-md) !important;
}

.iress-inline--lg > * {
  margin-bottom: var(--iress-spacing-md) !important;
}

.iress-inline--lg.iress-inline--left > * {
  margin-right: var(--iress-spacing-md) !important;
  margin-left: 0 !important;
}

.iress-inline--lg.iress-inline--right > * {
  margin-left: var(--iress-spacing-md) !important;
  margin-right: 0 !important;
}

.iress-inline--lg.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-md) / 2) !important;
  margin-right: calc(var(--iress-spacing-md) / 2) !important;
}

.iress-inline--xl {
  margin-bottom: var(--iress-spacing-neg-lg);
}

.iress-inline--xl > * {
  margin-bottom: var(--iress-spacing-lg) !important;
}

.iress-inline--xl.iress-inline--left > * {
  margin-right: var(--iress-spacing-lg) !important;
  margin-left: 0 !important;
}

.iress-inline--xl.iress-inline--right > * {
  margin-left: var(--iress-spacing-lg) !important;
  margin-right: 0 !important;
}

.iress-inline--xl.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-lg) / 2) !important;
  margin-right: calc(var(--iress-spacing-lg) / 2) !important;
}

@media only screen and (min-width: 576px) {
  .iress-inline--left.iress-inline-sm--none > *,
  .iress-inline--center.iress-inline-sm--none
    > *
    .iress-inline--right.iress-inline-sm--none
    > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-sm--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-sm--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-sm--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-sm--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-sm--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-sm--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-sm--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-sm--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-sm--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-sm--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-sm--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-sm--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-sm--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-sm--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 768px) {
  .iress-inline--left.iress-inline-md--none > *,
  .iress-inline--center.iress-inline-md--none > *,
  .iress-inline--right.iress-inline-md--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-md--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-md--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-md--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-md--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-md--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-md--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-md--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-md--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-md--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-md--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-md--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-md--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-md--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-md--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .iress-inline--left.iress-inline-lg--none > *,
  .iress-inline--center.iress-inline-lg--none > *,
  .iress-inline--right.iress-inline-lg--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-lg--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-lg--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-lg--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-lg--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-lg--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-lg--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-lg--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-lg--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-lg--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-lg--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-lg--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-lg--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-lg--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-lg--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .iress-inline--left.iress-inline-xl--none > *,
  .iress-inline--center.iress-inline-xl--none > *,
  .iress-inline--right.iress-inline-xl--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-xl--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-xl--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-xl--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-xl--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-xl--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-xl--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-xl--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-xl--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-xl--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-xl--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-xl--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-xl--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-xl--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-xl--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}
