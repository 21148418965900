.iress-theme-light {
  --iress-select-margin-top: var(--iress-spacing-xxs);
  --iress-select-margin-bottom: var(--iress-spacing-xxs);
  --iress-select-border-size: var(--iress-border-sm);
  --iress-select-border-color: var(--iress-border-color--form-control);
  --iress-select-border-color-focus: var(--iress-global-focus-color);
  --iress-select-border-color-invalid: var(--iress-global-danger);
  --iress-select-border-color-invalid-focus: var(--iress-global-danger);
  --iress-select-box-shadow-focus: var(--iress-global-focus);
  --iress-select-border-radius: var(--iress-border-radius-sm);
  --iress-select-font-size: inherit;
  --iress-select-color: var(--iress-global-text-color);
  --iress-select-color-focus: var(--iress-global-text-color);
  --iress-select-placeholder-color: var(--iress-global-text-color--muted);
  --iress-select-padding-x: var(--iress-spacing-xs);
  --iress-select-padding-y: var(--iress-spacing-xs);
  --iress-select-background-color: var(--iress-color-white);
  --iress-select-background-color-focus: var(--iress-color-white);
  --iress-select-option-color: var(--iress-global-text-color);
}
