.iress-theme-light {
  --col-1: 8.3333%;
  --col-2: 16.6667%;
  --col-3: 25%;
  --col-4: 33.3333%;
  --col-5: 41.6667%;
  --col-6: 50%;
  --col-7: 58.3333%;
  --col-8: 66.6667%;
  --col-9: 75%;
  --col-10: 83.3333%;
  --col-11: 91.6667%;
  --col-12: 100%;
}
