.iress-theme-light {
  --iress-field-margin-bottom: var(--iress-spacing-lg);
  --iress-field-border-top: 0;
  --iress-field-border-bottom: 0;
  --iress-field-border-left: var(--iress-border-lg);
  --iress-field-border-right: 0;
  --iress-field-border-color: var(--iress-border-color-standard);
  --iress-field-border-color-focus: var(--iress-global-focus-color);
  --iress-field-margin-x: 0;
  --iress-field-padding-left: var(--iress-spacing-sm);
  --iress-field-padding-right: var(--iress-spacing-xs);
  --iress-field-padding-y: 0;
  --iress-field-background-color: var(--iress-color-transparent);
  --iress-field-background-color-focus: var(--iress-color-transparent);
  /* stylelint-disable */
  --iress-field-transition: background-color, border-color,
    var(--iress-hover-transition-duration)
      var(--iress-hover-transition-timing-function);
  /* stylelint-enable */
  --iress-field-hint-color: var(--iress-global-text-color--muted);
  --iress-field-multiple-elements-label-color: var(
    --iress-global-text-color--muted
  );
}
