.iress-theme-light {
  --iress-badge-primary-background-color: var(--iress-global-primary);
  --iress-badge-primary-border-color: var(--iress-global-primary);
  --iress-badge-primary-color: var(--iress-color-white);
  --iress-badge-success-background-color: var(--iress-global-success);
  --iress-badge-success-border-color: var(--iress-global-success);
  --iress-badge-success-color: var(--iress-color-white);
  --iress-badge-warning-background-color: var(--iress-global-warning);
  --iress-badge-warning-border-color: var(--iress-global-warning);
  --iress-badge-warning-color: var(--iress-color-white);
  --iress-badge-danger-background-color: var(--iress-global-danger);
  --iress-badge-danger-border-color: var(--iress-global-danger);
  --iress-badge-danger-color: var(--iress-color-white);
  --iress-badge-info-background-color: var(--iress-global-info);
  --iress-badge-info-border-color: var(--iress-global-info);
  --iress-badge-info-color: var(--iress-color-white);
  --iress-badge-transparent-background-color: transparent;
  --iress-badge-transparent-border-color: var(--iress-global-text-color);
  --iress-badge-transparent-color: var(--iress-global-text-color);
}
