.iress-theme-light {
  --iress-alert-heading-font-size: var(--iress-font-size-xs);
  --iress-alert-heading-font-weight: var(--iress-font-weight-base-bold);
  --iress-alert-border-radius: var(--iress-border-radius-md);
  --iress-alert-padding-x: var(--iress-spacing-md);
  --iress-alert-padding-y: var(--iress-spacing-sm);
  --iress-alert-margin-bottom: var(--iress-spacing-lg);
  --iress-alert-border: var(--iress-border-sm);
  --iress-alert-background-color-error: var(--iress-global-danger--contrast);
  --iress-alert-color-error: var(--iress-global-danger);
  --iress-alert-border-color-error: var(--iress-global-danger);
  --iress-alert-background-color-warning: var(--iress-global-warning--contrast);
  --iress-alert-color-warning: var(--iress-global-warning);
  --iress-alert-border-color-warning: var(--iress-global-warning);
  --iress-alert-background-color-success: var(--iress-global-success--contrast);
  --iress-alert-color-success: var(--iress-global-success);
  --iress-alert-border-color-success: var(--iress-global-success);
  --iress-alert-background-color-info: var(--iress-global-info--contrast);
  --iress-alert-color-info: var(--iress-global-info);
  --iress-alert-border-color-info: var(--iress-global-info);
}
