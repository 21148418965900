.iress-theme-light {
  /* base */
  --iress-global-background-color: var(--iress-color-white);
  --iress-global-background-color--alt: var(--iress-color-grey-0);
  --iress-global-focus-color: var(--iress-color-s-blue);
  --iress-global-focus-width: 3px;
  --iress-global-focus: 0 0 0 var(--iress-global-focus-width, 2px)
    var(--iress-global-focus-color, blue);

  /* Buy / sell colours */
  --iress-global-sell-color: var(--iress-color-sell-6);
  --iress-global-sell-color--hover: var(--iress-color-sell-5);
  --iress-global-sell-color--active: var(--iress-color-sell-4);
  --iress-global-buy-color: var(--iress-color-buy-5);
  --iress-global-buy-color--hover: var(--iress-color-buy-4);
  --iress-global-buy-color--active: var(--iress-color-buy-3);

  /* Core theme colours */
  --iress-global-primary: var(--iress-color-purple);
  --iress-global-primary-contrast: var(--iress-color-white);

  /* System colours */
  --iress-global-danger: var(--iress-color-maroon);
  --iress-global-danger--contrast: var(--iress-color-maroon--contrast);
  --iress-global-warning: var(--iress-color-orange--light-theme);
  --iress-global-warning--contrast: var(--iress-color-orange--contrast);
  --iress-global-success: var(--iress-color-jade--d);
  --iress-global-success--contrast: var(--iress-color-jade--d-contrast);
  --iress-global-info: var(--iress-color-s-blue--d);
  --iress-global-info--contrast: var(--iress-color-s-blue--d-contrast);
}
