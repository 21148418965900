.iress-theme-light {
  --iress-button-border-width: var(--iress-border-sm);
  --iress-button-border-radius: var(--iress-border-radius-sm);
  --iress-button-font-family: var(--iress-font-family-base);
  --iress-button-font-size: var(--iress-font-size-xxs);
  --iress-button-font-weight: var(--iress-font-weight-base-regular);
  --iress-button-padding-x: var(--iress-spacing-sm);
  --iress-button-padding-y: var(--iress-spacing-xs);
  --iress-button-transition-timing-function: var(
    --iress-hover-transition-timing-function
  );
  --iress-button-transition-duration: var(--iress-hover-transition-duration);
  --iress-button-margin-right: var(--iress-spacing-xxs);

  /* Primary button */
  --iress-button-primary-background-color: var(--iress-global-primary);
  --iress-button-primary-color: var(--iress-color-white);
  --iress-button-primary-box-shadow: none;
  --iress-button-primary-border-color: var(--iress-global-primary);
  --iress-button-primary-text-decoration: none;
  --iress-button-primary-background-color-hover: var(--iress-color-purple--ll);
  --iress-button-primary-color-hover: var(--iress-color-white);
  --iress-button-primary-box-shadow-hover: none;
  --iress-button-primary-border-color-hover: var(--iress-color-purple--ll);
  --iress-button-primary-text-decoration-hover: none;
  --iress-button-primary-background-color-active: var(
    --iress-color-purple--llll
  );
  --iress-button-primary-color-active: var(--iress-color-white);
  --iress-button-primary-box-shadow-active: none;
  --iress-button-primary-border-color-active: var(--iress-color-purple--llll);
  --iress-button-primary-text-decoration-active: none;

  /* Secondary button */
  --iress-button-secondary-background-color: var(--iress-color-white);
  --iress-button-secondary-color: var(--iress-global-primary);
  --iress-button-secondary-box-shadow: none;
  --iress-button-secondary-border-color: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration: none;
  --iress-button-secondary-background-color-hover: var(--iress-color-grey-1);
  --iress-button-secondary-color-hover: var(--iress-global-primary);
  --iress-button-secondary-box-shadow-hover: none;
  --iress-button-secondary-border-color-hover: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration-hover: none;
  --iress-button-secondary-background-color-active: var(--iress-color-grey-2);
  --iress-button-secondary-color-active: var(--iress-global-primary);
  --iress-button-secondary-box-shadow-active: none;
  --iress-button-secondary-border-color-active: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration-active: none;

  /* Buy button */
  --iress-button-buy-background-color: var(--iress-global-buy-color);
  --iress-button-buy-color: var(--iress-global-text-color);
  --iress-button-buy-box-shadow: none;
  --iress-button-buy-border-color: var(--iress-global-buy-color);
  --iress-button-buy-text-decoration: none;

  --iress-button-buy-background-color-hover: var(
    --iress-global-buy-color--hover
  );
  --iress-button-buy-color-hover: var(--iress-global-text-color);
  --iress-button-buy-box-shadow-hover: none;
  --iress-button-buy-border-color-hover: var(--iress-global-buy-color--hover);
  --iress-button-buy-text-decoration-hover: none;

  --iress-button-buy-background-color-active: var(
    --iress-global-buy-color--active
  );
  --iress-button-buy-color-active: var(--global-text-color);
  --iress-button-buy-box-shadow-active: none;
  --iress-button-buy-border-color-active: var(--iress-global-buy-color--active);
  --iress-button-buy-text-decoration-active: none;

  /* Sell button */
  --iress-button-sell-background-color: var(--iress-global-sell-color);
  --iress-button-sell-color: var(--iress-global-text-color);
  --iress-button-sell-box-shadow: none;
  --iress-button-sell-border-color: var(--iress-global-sell-color);
  --iress-button-sell-text-decoration: none;

  --iress-button-sell-background-color-hover: var(
    --iress-global-sell-color--hover
  );
  --iress-button-sell-color-hover: var(--iress-global-text-color);
  --iress-button-sell-box-shadow-hover: none;
  --iress-button-sell-border-color-hover: var(--iress-global-sell-color--hover);
  --iress-button-sell-text-decoration-hover: none;

  --iress-button-sell-background-color-active: var(
    --iress-global-sell-color--active
  );
  --iress-button-sell-color-active: var(--global-text-color);
  --iress-button-sell-box-shadow-active: none;
  --iress-button-sell-border-color-active: var(
    --iress-global-sell-color--active
  );
  --iress-button-sell-text-decoration-active: none;

  /* Tertiary button */
  --iress-button-tertiary-background-color: transparent;
  --iress-button-tertiary-color: var(--iress-global-link-color);
  --iress-button-tertiary-box-shadow: none;
  --iress-button-tertiary-border-color: transparent;
  --iress-button-tertiary-text-decoration: none;
  --iress-button-tertiary-background-color-hover: transparent;
  --iress-button-tertiary-color-hover: var(--iress-global-link-color-hover);
  --iress-button-tertiary-box-shadow-hover: none;
  --iress-button-tertiary-border-color-hover: transparent;
  --iress-button-tertiary-text-decoration-hover: none;
  --iress-button-tertiary-background-color-active: transparent;
  --iress-button-tertiary-color-active: var(--iress-global-link-color-active);
  --iress-button-tertiary-box-shadow-active: none;
  --iress-button-tertiary-border-color-active: transparent;
  --iress-button-tertiary-text-decoration-active: none;

  /* Link button */
  --iress-button-link-background-color: transparent;
  --iress-button-link-color: var(--iress-global-link-color);
  --iress-button-link-box-shadow: none;
  --iress-button-link-border-color: transparent;
  --iress-button-link-text-decoration: var(--iress-global-link-text-decoration);
  --iress-button-link-background-color-hover: transparent;
  --iress-button-link-color-hover: var(--iress-global-link-color-hover);
  --iress-button-link-box-shadow-hover: none;
  --iress-button-link-border-color-hover: transparent;
  --iress-button-link-text-decoration-hover: var(
    --iress-global-link-text-decoration-hover
  );
  --iress-button-link-background-color-active: transparent;
  --iress-button-link-color-active: var(--iress-global-link-color-active);
  --iress-button-link-box-shadow-active: none;
  --iress-button-link-border-color-active: transparent;
  --iress-button-link-text-decoration-active: var(
    --iress-global-link-text-decoration-active
  );
}
