.iress-theme-light {
  --iress-spacing-xxs: 0.25rem; /* 3.5px */
  --iress-spacing-xs: 0.5rem; /* 7px */
  --iress-spacing-sm: 0.75rem; /* 10.5px */
  --iress-spacing-md: 1rem; /* 14px */
  --iress-spacing-lg: 1.5rem; /* 21px */
  --iress-spacing-xl: 2rem; /* 28px */
  --iress-spacing-xxl: 3rem; /* 42px */
  --iress-spacing-neg-xxs: -0.25rem; /* -3.5px */
  --iress-spacing-neg-xs: -0.5rem; /* -7px */
  --iress-spacing-neg-sm: -0.75rem; /* -10.5px */
  --iress-spacing-neg-md: -1rem; /* -14px */
  --iress-spacing-neg-lg: -1.5rem; /* -21px */
  --iress-spacing-neg-xl: -2rem; /* -28px */
  --iress-spacing-neg-xxl: -3rem; /* -42px */
}
