.iress-theme-light {
  --iress-radio-margin-bottom: var(--iress-spacing-xs);
  --iress-radio-border-color: var(--iress-border-color--form-control);
  --iress-radio-border-color-checked: var(--iress-color-purple);
  --iress-radio-background-color: var(--iress-color-white);
  --iress-radio-background-color-checked: var(--iress-color-white);
  --iress-radio-inner-background-color-hover: var(--iress-color-grey-2);
  --iress-radio-inner-background-color-checked: var(--iress-color-purple);
  --iress-radio-inner-background-color-checked-hover: var(
    --iress-color-purple--ll
  );
}
