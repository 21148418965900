@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500&display=swap);
.iress-theme-light {
  /* Border-width */
  --iress-border-none: 0;
  --iress-border-sm: 1px;
  --iress-border-lg: 2px;

  /* Border radius */
  --iress-border-radius-sm: 0.125rem;
  --iress-border-radius-md: 0.25rem;
  --iress-border-radius-lg: 0.75rem;

  /* Border colors */
  --iress-border-color--standard: var(--iress-color-grey-2);
  --iress-border-color--form-control: var(--iress-color-grey-6);
}

.iress-theme-light {
  --iress-color-purple: hsl(283, 43%, 19%);
  --iress-color-purple--l: hsl(283, 43%, 24%); /* Headings */
  --iress-color-purple--ll: hsl(283, 43%, 29%);
  --iress-color-purple--lll: hsl(283, 43%, 34%);
  --iress-color-purple--llll: hsl(283, 43%, 39%);
  --iress-color-pink: hsl(351, 100%, 63%);
  --iress-color-green: hsl(158, 91%, 55%);
  --iress-color-green--l: hsl(158, 91%, 70%); /* ?? */
  --iress-color-green--ll: hsl(158, 91%, 80%); /* ?? */
  --iress-color-cyan: hsl(188, 100%, 50%);
  --iress-color-cyan--d: hsl(188, 100%, 40%);
  --iress-color-cyan--dd: hsl(188, 100%, 30%);
  --iress-color-cyan--l: hsl(188, 100%, 60%);
  --iress-color-cyan--ll: hsl(188, 100%, 70%);
  --iress-color-yellow: hsl(55, 98%, 53%);
  --iress-color-grey: hsl(300, 9%, 93%);
  --iress-color-blackberry: hsl(316, 100%, 13%);
  --iress-color-jade: hsl(155, 100%, 29%);
  --iress-color-jade--l: hsl(155, 100%, 34%);
  --iress-color-jade--d: hsl(155, 100%, 25%);
  --iress-color-jade--d-contrast: hsl(155, 100%, 90%);
  --iress-color-maroon: hsl(347, 68%, 45%);
  --iress-color-maroon--contrast: hsl(347, 68%, 93%);
  --iress-color-s-yellow: hsl(43, 100%, 45%); /* 6.7:1 dark */
  --iress-color-s-blue: hsl(206, 100%, 40%);
  --iress-color-s-blue--contrast: hsl(206, 100%, 98%);
  --iress-color-s-blue--l: hsl(206, 100%, 50%); /* Dark focus, link & info */
  --iress-color-s-blue--d: hsl(206, 100%, 35%); /* ?? */
  --iress-color-s-blue--dd: hsl(206, 100%, 20%); /* ?? */
  --iress-color-s-blue--ddd: hsl(206, 100%, 10%); /* ?? */
  --iress-color-s-blue--d-contrast: hsl(206, 100%, 95%);
  --iress-color-chatelle: hsl(280, 10%, 77%);
  --iress-color-white: hsl(0, 0%, 100%);
  --iress-color-black: hsl(0, 0%, 0%);
  --iress-color-grey-12: hsl(0, 0%, 7%); /* light text, dark page bg */
  --iress-color-grey-11: hsl(0, 0%, 15%); /* dark theme alt panel */
  --iress-color-grey-10: hsl(0, 0%, 23%);
  --iress-color-grey-9: hsl(0, 0%, 31%);
  --iress-color-grey-8: hsl(0, 0%, 38%); /* light muted, dark placeholder */
  --iress-color-grey-7: hsl(0, 0%, 46%); /* dark theme border (3:1) */
  --iress-color-grey-6: hsl(0, 0%, 54%); /* light theme border (3:1) */
  --iress-color-grey-5: hsl(0, 0%, 62%); /* Dark muted, light placeholder */
  --iress-color-grey-4: hsl(0, 0%, 69%);
  --iress-color-grey-3: hsl(0, 0%, 77%);
  --iress-color-grey-2: hsl(0, 0%, 85%); /* dark theme heading & lead text */
  --iress-color-grey-1: hsl(0, 0%, 92%); /* dark theme text */
  --iress-color-grey-0: hsl(0, 0%, 97%); /* light theme alt panel */
  --iress-color-orange--light-theme: hsl(11, 91%, 44%); /* 3:1 dark */
  --iress-color-orange--dark-theme: hsl(11, 91%, 59%); /* 4.5:1 dark */
  --iress-color-orange--contrast: hsl(11, 91%, 97%);
  --iress-color-transparent: transparent;
  --iress-color-sell-6: hsl(86, 53%, 31%);
  --iress-color-sell-5: hsl(86, 53%, 36%);
  --iress-color-sell-4: hsl(86, 53%, 41%);
  --iress-color-sell-3: hsl(86, 53%, 51%);
  --iress-color-sell-2: hsl(86, 53%, 61%);
  --iress-color-sell-1: hsl(86, 53%, 71%);
  --iress-color-buy-5: hsl(2, 84%, 45%);
  --iress-color-buy-4: hsl(2, 84%, 55%);
  --iress-color-buy-3: hsl(2, 84%, 65%);
  --iress-color-buy-2: hsl(2, 84%, 75%);
  --iress-color-buy-1: hsl(2, 84%, 85%);
}

.iress-theme-light {
  --iress-hover-transition-timing-function: ease-in-out;
  --iress-hover-transition-duration: 0.15s;
}

.iress-theme-light {
  /* Font sizes */
  --iress-font-size-xxxs: 0.875rem;
  --iress-font-size-xxs: 1rem;
  --iress-font-size-xs: 1.125rem;
  --iress-font-size-sm: 1.25rem;
  --iress-font-size-md: 1.375rem;
  --iress-font-size-lg: 1.5rem;
  --iress-font-size-xl: 1.75rem;
  --iress-font-size-xxl: 2rem;
  --iress-font-size-xxxl: 2.5rem;
  --iress-font-size-xxxxl: 3.5rem;

  /* Line heights */
  --iress-line-height-sm: 1.2;
  --iress-line-height-md: 1.42857;

  /* Font families / weights */
  --iress-font-family-base: "Roboto", Arial, sans-serif;
  --iress-font-weight-base-lighter: 100;
  --iress-font-weight-base-light: 300;
  --iress-font-weight-base-regular: 400;
  --iress-font-weight-base-bold: 500;
  --iress-font-family-code: Consolas, Monaco, Lucida Console, Courier New;
}



.iress-theme-light {
  --iress-spacing-xxs: 0.25rem; /* 3.5px */
  --iress-spacing-xs: 0.5rem; /* 7px */
  --iress-spacing-sm: 0.75rem; /* 10.5px */
  --iress-spacing-md: 1rem; /* 14px */
  --iress-spacing-lg: 1.5rem; /* 21px */
  --iress-spacing-xl: 2rem; /* 28px */
  --iress-spacing-xxl: 3rem; /* 42px */
  --iress-spacing-neg-xxs: -0.25rem; /* -3.5px */
  --iress-spacing-neg-xs: -0.5rem; /* -7px */
  --iress-spacing-neg-sm: -0.75rem; /* -10.5px */
  --iress-spacing-neg-md: -1rem; /* -14px */
  --iress-spacing-neg-lg: -1.5rem; /* -21px */
  --iress-spacing-neg-xl: -2rem; /* -28px */
  --iress-spacing-neg-xxl: -3rem; /* -42px */
}

.iress-theme-light.iress-view-mode-compact,
.iress-theme-light.iress-view-mode-responsive {
  --iress-spacing-xxs: 0.125rem; /* 2px */
  --iress-spacing-xs: 0.25rem; /* 4px */
  --iress-spacing-sm: 0.5rem; /* 8px */
  --iress-spacing-md: 0.75rem; /* 12px */
  --iress-spacing-lg: 1rem; /* 16px */
  --iress-spacing-xl: 1.5rem; /* 24px */
  --iress-spacing-xxl: 2rem; /* 32px */
  --iress-spacing-neg-xxs: -0.125rem; /* -2px */
  --iress-spacing-neg-xs: -0.25rem; /* -4px */
  --iress-spacing-neg-sm: -0.5rem; /* -8px */
  --iress-spacing-neg-md: -0.75rem; /* -12px */
  --iress-spacing-neg-lg: -1rem; /* -16px */
  --iress-spacing-neg-xl: -1.5rem; /* -24px */
  --iress-spacing-neg-xxl: -2rem; /* -32px */
}

.iress-theme-light.iress-view-mode-compact,
.iress-theme-light.iress-view-mode-responsive {
  --iress-spacing-xxs: 0.125rem; /* 2px */
  --iress-spacing-xs: 0.25rem; /* 4px */
  --iress-spacing-sm: 0.5rem; /* 8px */
  --iress-spacing-md: 0.75rem; /* 12px */
  --iress-spacing-lg: 1rem; /* 16px */
  --iress-spacing-xl: 1.5rem; /* 24px */
  --iress-spacing-xxl: 2rem; /* 32px */
  --iress-spacing-neg-xxs: -0.125rem; /* -2px */
  --iress-spacing-neg-xs: -0.25rem; /* -4px */
  --iress-spacing-neg-sm: -0.5rem; /* -8px */
  --iress-spacing-neg-md: -0.75rem; /* -12px */
  --iress-spacing-neg-lg: -1rem; /* -16px */
  --iress-spacing-neg-xl: -1.5rem; /* -24px */
  --iress-spacing-neg-xxl: -2rem; /* -32px */
}

@media only screen and (min-width: 768px) {
  .iress-theme-light.iress-view-mode-responsive {
    --iress-spacing-xxs: 0.25rem; /* 3.5px */
    --iress-spacing-xs: 0.5rem; /* 7px */
    --iress-spacing-sm: 0.75rem; /* 10.5px */
    --iress-spacing-md: 1rem; /* 14px */
    --iress-spacing-lg: 1.5rem; /* 21px */
    --iress-spacing-xl: 2rem; /* 28px */
    --iress-spacing-xxl: 3rem; /* 42px */
    --iress-spacing-neg-xxs: -0.25rem; /* -3.5px */
    --iress-spacing-neg-xs: -0.5rem; /* -7px */
    --iress-spacing-neg-sm: -0.75rem; /* -10.5px */
    --iress-spacing-neg-md: -1rem; /* -14px */
    --iress-spacing-neg-lg: -1.5rem; /* -21px */
    --iress-spacing-neg-xl: -2rem; /* -28px */
    --iress-spacing-neg-xxl: -3rem; /* -42px */
  }
}

@media only screen and (min-width: 1200px) {
  .iress-theme-light.iress-view-mode-responsive {
    --iress-spacing-xxs: 0.25rem; /* 4px */
    --iress-spacing-xs: 0.5rem; /* 8px */
    --iress-spacing-sm: 0.75rem; /* 12px */
    --iress-spacing-md: 1rem; /* 16px */
    --iress-spacing-lg: 1.5rem; /* 24px */
    --iress-spacing-xl: 2rem; /* 32px */
    --iress-spacing-xxl: 3rem; /* 48px */
    --iress-spacing-neg-xxs: -0.25rem; /* -4px */
    --iress-spacing-neg-xs: -0.5rem; /* -8px */
    --iress-spacing-neg-sm: -0.75rem; /* -12px */
    --iress-spacing-neg-md: -1rem; /* -16px */
    --iress-spacing-neg-lg: -1.5rem; /* -24px */
    --iress-spacing-neg-xl: -2rem; /* -32px */
    --iress-spacing-neg-xxl: -3rem; /* -48px */
  }
}

.iress-theme-light.iress-view-mode-touch {
  --iress-spacing-xxs: 0.25rem; /* 4px */
  --iress-spacing-xs: 0.5rem; /* 8px */
  --iress-spacing-sm: 0.75rem; /* 12px */
  --iress-spacing-md: 1rem; /* 16px */
  --iress-spacing-lg: 1.5rem; /* 24px */
  --iress-spacing-xl: 2rem; /* 32px */
  --iress-spacing-xxl: 3rem; /* 48px */
  --iress-spacing-neg-xxs: -0.25rem; /* -4px */
  --iress-spacing-neg-xs: -0.5rem; /* -8px */
  --iress-spacing-neg-sm: -0.75rem; /* -12px */
  --iress-spacing-neg-md: -1rem; /* -16px */
  --iress-spacing-neg-lg: -1.5rem; /* -24px */
  --iress-spacing-neg-xl: -2rem; /* -32px */
  --iress-spacing-neg-xxl: -3rem; /* -48px */
}



.iress-theme-light {
  /* base */
  --iress-global-background-color: var(--iress-color-white);
  --iress-global-background-color--alt: var(--iress-color-grey-0);
  --iress-global-focus-color: var(--iress-color-s-blue);
  --iress-global-focus-width: 3px;
  --iress-global-focus: 0 0 0 var(--iress-global-focus-width, 2px)
    var(--iress-global-focus-color, blue);

  /* Buy / sell colours */
  --iress-global-sell-color: var(--iress-color-sell-6);
  --iress-global-sell-color--hover: var(--iress-color-sell-5);
  --iress-global-sell-color--active: var(--iress-color-sell-4);
  --iress-global-buy-color: var(--iress-color-buy-5);
  --iress-global-buy-color--hover: var(--iress-color-buy-4);
  --iress-global-buy-color--active: var(--iress-color-buy-3);

  /* Core theme colours */
  --iress-global-primary: var(--iress-color-purple);
  --iress-global-primary-contrast: var(--iress-color-white);

  /* System colours */
  --iress-global-danger: var(--iress-color-maroon);
  --iress-global-danger--contrast: var(--iress-color-maroon--contrast);
  --iress-global-warning: var(--iress-color-orange--light-theme);
  --iress-global-warning--contrast: var(--iress-color-orange--contrast);
  --iress-global-success: var(--iress-color-jade--d);
  --iress-global-success--contrast: var(--iress-color-jade--d-contrast);
  --iress-global-info: var(--iress-color-s-blue--d);
  --iress-global-info--contrast: var(--iress-color-s-blue--d-contrast);
}

.iress-theme-light {
  /* base */
  --iress-global-text-color: var(--iress-color-grey-11);
  --iress-global-text-color--contrast: var(--iress-color-white);
  --iress-global-text-color--muted: var(
    --iress-color-grey-8
  ); /* Gives 4.5:1 against panel bg */
  --iress-global-font-family: var(--iress-font-family-base);
  --iress-global-line-height: var(--iress-line-height-md);

  /* links */
  --iress-global-link-color: var(--iress-color-s-blue);
  --iress-global-link-text-decoration: underline;
  --iress-global-link-color-hover: var(--iress-color-s-blue--dd);
  --iress-global-link-text-decoration-hover: none;
  --iress-global-link-color-active: var(--iress-color-s-blue--ddd);
  --iress-global-link-text-decoration-active: none;

  /* headings */
  --iress-global-heading-font-family: var(--iress-font-family-base);
  --iress-global-h1-font-weight: var(--iress-font-weight-base-lighter);
  --iress-global-heading-font-weight: var(--iress-font-weight-base-light);
  --iress-global-heading-line-height: var(--iress-line-height-md);
  --iress-global-heading-margin-top: var(--iress-spacing-xl);
  --iress-global-heading-margin-bottom: var(--iress-spacing-xs);
  --iress-global-heading-color: var(--iress-color-purple--l);
  --iress-global-h1-font-size: var(--iress-font-size-xxl);
  --iress-global-h2-font-size: var(--iress-font-size-lg);
  --iress-global-h3-font-size: var(--iress-font-size-md);
  --iress-global-h4-font-size: var(--iress-font-size-sm);
  --iress-global-h5-font-size: var(--iress-font-size-xs);
  --iress-global-h6-font-size: var(--iress-font-size-xxs);

  /* display */
  --iress-global-display-font-family: var(--iress-font-family-base);
  --iress-global-display-font-weight: var(--iress-font-weight-base-lighter);
  --iress-global-display-line-height: var(--iress-line-height-sm);
  --iress-global-display-margin-top: 0;
  --iress-global-display-margin-bottom: 0;
  --iress-global-display-color: var(--iress-color-grey-9);
  --iress-global-display1-font-size: var(--iress-font-size-xxxxl);
  --iress-global-display2-font-size: var(--iress-font-size-xxxl);
  --iress-global-display3-font-size: var(--iress-font-size-xl);

  /* lead */
  --iress-global-lead-color: var(--iress-color-grey-12);
  --iress-global-lead-font-size: var(--iress-font-size-sm);
  --iress-global-lead-font-weight: var(--iress-font-weight-base-light);

  /* Placeholder */
  --iress-global-placeholder-color: var(--iress-color-grey-5);

  /* caption */
  --iress-global-caption-color: var(--iress-global-text-color--muted);
  --iress-global-caption-font-size: inherit;

  /* code */
  --iress-global-code-color: var(--iress-color-maroon);
  --iress-global-code-background-color: var(
    --iress-global-background-color--alt
  );
  --iress-global-code-border: transparent;
  --iress-global-code-border-color: var(--iress-color-grey-2);
  --iress-global-code-font-family: var(--iress-font-family-code);
  --iress-global-code-font-size: inherit;
  --iress-global-code-padding: 2px 4px;
  --iress-global-code-line-height: var(--iress-line-height-md);
}

/* View modes - need to be moved to component vars (with defaults) when text component is created
 as these aren't actually related to the theme */
.iress-theme-light.iress-view-mode-touch {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-light);
  --iress-global-font-size: 16px;
  --iress-global-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-link-font-weight: var(--iress-font-weight-base-regular);
}
.iress-theme-light.iress-view-mode-standard {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-light);
  --iress-global-font-size: 14px;
  --iress-global-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-link-font-weight: var(--iress-font-weight-base-regular);
}
.iress-theme-light.iress-view-mode-compact {
  --iress-global-caption-font-weight: var(--iress-font-weight-base-regular);
  --iress-global-font-size: 12px;
  --iress-global-font-weight: var(--iress-font-weight-base-bold);
  --iress-global-link-font-weight: var(--iress-font-weight-base-bold);
}

.iress-theme-light {
  --col-1: 8.3333%;
  --col-2: 16.6667%;
  --col-3: 25%;
  --col-4: 33.3333%;
  --col-5: 41.6667%;
  --col-6: 50%;
  --col-7: 58.3333%;
  --col-8: 66.6667%;
  --col-9: 75%;
  --col-10: 83.3333%;
  --col-11: 91.6667%;
  --col-12: 100%;
}

.iress-theme-light {
  --iress-panel-border: var(--iress-border-none);
  --iress-panel-padding-sm: var(--iress-spacing-md);
  --iress-panel-padding-lg: var(--iress-spacing-xl);
  --iress-panel-background-color: var(--iress-global-background-color--alt);
  --iress-panel-color: var(--iress-global-text-color);
  --iress-panel-border-color: transparent;
  --iress-panel-background-color-inversed: var(--iress-global-background-color);
  --iress-panel-color-inversed: var(--iress-global-text-color);
  --iress-panel-border-color-inversed: transparent;
}

.iress-theme-light {
  --iress-row-gutter-sm: var(--iress-spacing-xs);
  --iress-row-neg-gutter-sm: var(--iress-spacing-neg-xs);
  --iress-row-gutter-md: var(--iress-spacing-md);
  --iress-row-neg-gutter-md: var(--iress-spacing-neg-md);
  --iress-row-gutter-lg: var(--iress-spacing-lg);
  --iress-row-neg-gutter-lg: var(--iress-spacing-neg-lg);
}

.iress-theme-light {
  --iress-container-padding-x: var(--iress-spacing-md);
  --iress-container-max-width-sm: 520px;
  --iress-container-max-width-md: 740px;
  --iress-container-max-width-lg: 960px;
  --iress-container-max-width-xl: 1160px;
}

.iress-theme-light {
  --iress-button-border-width: var(--iress-border-sm);
  --iress-button-border-radius: var(--iress-border-radius-sm);
  --iress-button-font-family: var(--iress-font-family-base);
  --iress-button-font-size: var(--iress-font-size-xxs);
  --iress-button-font-weight: var(--iress-font-weight-base-regular);
  --iress-button-padding-x: var(--iress-spacing-sm);
  --iress-button-padding-y: var(--iress-spacing-xs);
  --iress-button-transition-timing-function: var(
    --iress-hover-transition-timing-function
  );
  --iress-button-transition-duration: var(--iress-hover-transition-duration);
  --iress-button-margin-right: var(--iress-spacing-xxs);

  /* Primary button */
  --iress-button-primary-background-color: var(--iress-global-primary);
  --iress-button-primary-color: var(--iress-color-white);
  --iress-button-primary-box-shadow: none;
  --iress-button-primary-border-color: var(--iress-global-primary);
  --iress-button-primary-text-decoration: none;
  --iress-button-primary-background-color-hover: var(--iress-color-purple--ll);
  --iress-button-primary-color-hover: var(--iress-color-white);
  --iress-button-primary-box-shadow-hover: none;
  --iress-button-primary-border-color-hover: var(--iress-color-purple--ll);
  --iress-button-primary-text-decoration-hover: none;
  --iress-button-primary-background-color-active: var(
    --iress-color-purple--llll
  );
  --iress-button-primary-color-active: var(--iress-color-white);
  --iress-button-primary-box-shadow-active: none;
  --iress-button-primary-border-color-active: var(--iress-color-purple--llll);
  --iress-button-primary-text-decoration-active: none;

  /* Secondary button */
  --iress-button-secondary-background-color: var(--iress-color-white);
  --iress-button-secondary-color: var(--iress-global-primary);
  --iress-button-secondary-box-shadow: none;
  --iress-button-secondary-border-color: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration: none;
  --iress-button-secondary-background-color-hover: var(--iress-color-grey-1);
  --iress-button-secondary-color-hover: var(--iress-global-primary);
  --iress-button-secondary-box-shadow-hover: none;
  --iress-button-secondary-border-color-hover: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration-hover: none;
  --iress-button-secondary-background-color-active: var(--iress-color-grey-2);
  --iress-button-secondary-color-active: var(--iress-global-primary);
  --iress-button-secondary-box-shadow-active: none;
  --iress-button-secondary-border-color-active: var(
    --iress-border-color--form-control
  );
  --iress-button-secondary-text-decoration-active: none;

  /* Buy button */
  --iress-button-buy-background-color: var(--iress-global-buy-color);
  --iress-button-buy-color: var(--iress-global-text-color);
  --iress-button-buy-box-shadow: none;
  --iress-button-buy-border-color: var(--iress-global-buy-color);
  --iress-button-buy-text-decoration: none;

  --iress-button-buy-background-color-hover: var(
    --iress-global-buy-color--hover
  );
  --iress-button-buy-color-hover: var(--iress-global-text-color);
  --iress-button-buy-box-shadow-hover: none;
  --iress-button-buy-border-color-hover: var(--iress-global-buy-color--hover);
  --iress-button-buy-text-decoration-hover: none;

  --iress-button-buy-background-color-active: var(
    --iress-global-buy-color--active
  );
  --iress-button-buy-color-active: var(--global-text-color);
  --iress-button-buy-box-shadow-active: none;
  --iress-button-buy-border-color-active: var(--iress-global-buy-color--active);
  --iress-button-buy-text-decoration-active: none;

  /* Sell button */
  --iress-button-sell-background-color: var(--iress-global-sell-color);
  --iress-button-sell-color: var(--iress-global-text-color);
  --iress-button-sell-box-shadow: none;
  --iress-button-sell-border-color: var(--iress-global-sell-color);
  --iress-button-sell-text-decoration: none;

  --iress-button-sell-background-color-hover: var(
    --iress-global-sell-color--hover
  );
  --iress-button-sell-color-hover: var(--iress-global-text-color);
  --iress-button-sell-box-shadow-hover: none;
  --iress-button-sell-border-color-hover: var(--iress-global-sell-color--hover);
  --iress-button-sell-text-decoration-hover: none;

  --iress-button-sell-background-color-active: var(
    --iress-global-sell-color--active
  );
  --iress-button-sell-color-active: var(--global-text-color);
  --iress-button-sell-box-shadow-active: none;
  --iress-button-sell-border-color-active: var(
    --iress-global-sell-color--active
  );
  --iress-button-sell-text-decoration-active: none;

  /* Tertiary button */
  --iress-button-tertiary-background-color: transparent;
  --iress-button-tertiary-color: var(--iress-global-link-color);
  --iress-button-tertiary-box-shadow: none;
  --iress-button-tertiary-border-color: transparent;
  --iress-button-tertiary-text-decoration: none;
  --iress-button-tertiary-background-color-hover: transparent;
  --iress-button-tertiary-color-hover: var(--iress-global-link-color-hover);
  --iress-button-tertiary-box-shadow-hover: none;
  --iress-button-tertiary-border-color-hover: transparent;
  --iress-button-tertiary-text-decoration-hover: none;
  --iress-button-tertiary-background-color-active: transparent;
  --iress-button-tertiary-color-active: var(--iress-global-link-color-active);
  --iress-button-tertiary-box-shadow-active: none;
  --iress-button-tertiary-border-color-active: transparent;
  --iress-button-tertiary-text-decoration-active: none;

  /* Link button */
  --iress-button-link-background-color: transparent;
  --iress-button-link-color: var(--iress-global-link-color);
  --iress-button-link-box-shadow: none;
  --iress-button-link-border-color: transparent;
  --iress-button-link-text-decoration: var(--iress-global-link-text-decoration);
  --iress-button-link-background-color-hover: transparent;
  --iress-button-link-color-hover: var(--iress-global-link-color-hover);
  --iress-button-link-box-shadow-hover: none;
  --iress-button-link-border-color-hover: transparent;
  --iress-button-link-text-decoration-hover: var(
    --iress-global-link-text-decoration-hover
  );
  --iress-button-link-background-color-active: transparent;
  --iress-button-link-color-active: var(--iress-global-link-color-active);
  --iress-button-link-box-shadow-active: none;
  --iress-button-link-border-color-active: transparent;
  --iress-button-link-text-decoration-active: var(
    --iress-global-link-text-decoration-active
  );
}

.iress-theme-light {
  --iress-alert-heading-font-size: var(--iress-font-size-xs);
  --iress-alert-heading-font-weight: var(--iress-font-weight-base-bold);
  --iress-alert-border-radius: var(--iress-border-radius-md);
  --iress-alert-padding-x: var(--iress-spacing-md);
  --iress-alert-padding-y: var(--iress-spacing-sm);
  --iress-alert-margin-bottom: var(--iress-spacing-lg);
  --iress-alert-border: var(--iress-border-sm);
  --iress-alert-background-color-error: var(--iress-global-danger--contrast);
  --iress-alert-color-error: var(--iress-global-danger);
  --iress-alert-border-color-error: var(--iress-global-danger);
  --iress-alert-background-color-warning: var(--iress-global-warning--contrast);
  --iress-alert-color-warning: var(--iress-global-warning);
  --iress-alert-border-color-warning: var(--iress-global-warning);
  --iress-alert-background-color-success: var(--iress-global-success--contrast);
  --iress-alert-color-success: var(--iress-global-success);
  --iress-alert-border-color-success: var(--iress-global-success);
  --iress-alert-background-color-info: var(--iress-global-info--contrast);
  --iress-alert-color-info: var(--iress-global-info);
  --iress-alert-border-color-info: var(--iress-global-info);
}

.iress-theme-light {
  --iress-field-margin-bottom: var(--iress-spacing-lg);
  --iress-field-border-top: 0;
  --iress-field-border-bottom: 0;
  --iress-field-border-left: var(--iress-border-lg);
  --iress-field-border-right: 0;
  --iress-field-border-color: var(--iress-border-color-standard);
  --iress-field-border-color-focus: var(--iress-global-focus-color);
  --iress-field-margin-x: 0;
  --iress-field-padding-left: var(--iress-spacing-sm);
  --iress-field-padding-right: var(--iress-spacing-xs);
  --iress-field-padding-y: 0;
  --iress-field-background-color: var(--iress-color-transparent);
  --iress-field-background-color-focus: var(--iress-color-transparent);
  /* stylelint-disable */
  --iress-field-transition: background-color, border-color,
    var(--iress-hover-transition-duration)
      var(--iress-hover-transition-timing-function);
  /* stylelint-enable */
  --iress-field-hint-color: var(--iress-global-text-color--muted);
  --iress-field-multiple-elements-label-color: var(
    --iress-global-text-color--muted
  );
}

.iress-theme-light {
  --iress-input-margin-bottom: var(--iress-spacing-xs);
  --iress-input-border-size: var(--iress-border-sm);
  --iress-input-border-color: var(--iress-border-color--form-control);
  --iress-input-border-color-focus: var(--iress-border-color-active);
  --iress-input-border-color-invalid: var(--iress-global-danger);
  --iress-input-border-color-invalid-focus: var(--iress-global-danger);
  --iress-input-box-shadow-focus: var(--iress-global-focus);
  --iress-input-border-radius: var(--iress-border-radius-sm);
  --iress-input-font-size: var(--iress-global-font-size);
  --iress-input-color: var(--iress-global-text-color);
  --iress-input-color-focus: var(--iress-global-text-color);
  --iress-input-watermark-color: var(--iress-global-text-color--muted);
  --iress-input-placeholder-color: var(--iress-global-placeholder-color);
  --iress-input-padding-x: var(--iress-spacing-xs);
  --iress-input-padding-y: var(--iress-spacing-xs);
  --iress-input-background-color: var(--iress-color-white);
  --iress-input-background-color-focus: var(--iress-color-white);
  /* stylelint-disable */
  --iress-input-transition: background-color, color,
    border-color var(--iress-hover-transition-timing-function)
      var(--iress-hover-transition-duration);
  /* stylelint-enable */
  --iress-input-label-padding-bottom: var(--iress-spacing-xxs);
}
.iress-theme-light.iress-view-mode-touch {
  --iress-input-min-height: 2.5rem;
}
.iress-theme-light.iress-view-mode-standard {
  --iress-input-min-height: 2.5rem;
}
.iress-theme-light.iress-view-mode-compact {
  --iress-input-min-height: 2rem;
}

.iress-theme-light {
  --iress-label-color: var(--iress-global-text-color);
  --iress-label-font-size: inherit;
  --iress-label-required-color: var(--iress-global-danger);
  --iress-label-optional-color: var(--iress-global-text-color--muted);
  --iress-label-meta-font-size: 0.875em;
  --iress-label-meta-font-style: none;
}

.iress-theme-light {
  --iress-badge-primary-background-color: var(--iress-global-primary);
  --iress-badge-primary-border-color: var(--iress-global-primary);
  --iress-badge-primary-color: var(--iress-color-white);
  --iress-badge-success-background-color: var(--iress-global-success);
  --iress-badge-success-border-color: var(--iress-global-success);
  --iress-badge-success-color: var(--iress-color-white);
  --iress-badge-warning-background-color: var(--iress-global-warning);
  --iress-badge-warning-border-color: var(--iress-global-warning);
  --iress-badge-warning-color: var(--iress-color-white);
  --iress-badge-danger-background-color: var(--iress-global-danger);
  --iress-badge-danger-border-color: var(--iress-global-danger);
  --iress-badge-danger-color: var(--iress-color-white);
  --iress-badge-info-background-color: var(--iress-global-info);
  --iress-badge-info-border-color: var(--iress-global-info);
  --iress-badge-info-color: var(--iress-color-white);
  --iress-badge-transparent-background-color: transparent;
  --iress-badge-transparent-border-color: var(--iress-global-text-color);
  --iress-badge-transparent-color: var(--iress-global-text-color);
}

.iress-theme-light {
  --iress-checkbox-margin-bottom: var(--iress-spacing-sm);
  --iress-checkbox-margin-top: var(--iress-spacing-sm);
  --iress-checkbox-padding-left: var(--iress-spacing-lg);
  --iress-checkbox-border-radius: var(--iress-border-radius-sm);
  --iress-checkbox-border-color: var(--iress-border-color--form-control);
  --iress-checkbox-border-color-checked: var(--iress-color-purple);
  --iress-checkbox-background-color: var(--iress-color-white);
  --iress-checkbox-background-color-hover: var(--iress-color-grey-2);
  --iress-checkbox-background-color-checked: var(--iress-color-purple);
  --iress-checkbox-background-color-checked-hover: var(
    --iress-color-purple--ll
  );
  --iress-checkbox-mark-color: var(--iress-color-white);
}

.iress-theme-light {
  --iress-checkbox-group-inline-spacing: var(--iress-spacing-lg);
}

.iress-theme-light {
  --iress-radio-margin-bottom: var(--iress-spacing-xs);
  --iress-radio-border-color: var(--iress-border-color--form-control);
  --iress-radio-border-color-checked: var(--iress-color-purple);
  --iress-radio-background-color: var(--iress-color-white);
  --iress-radio-background-color-checked: var(--iress-color-white);
  --iress-radio-inner-background-color-hover: var(--iress-color-grey-2);
  --iress-radio-inner-background-color-checked: var(--iress-color-purple);
  --iress-radio-inner-background-color-checked-hover: var(
    --iress-color-purple--ll
  );
}

.iress-theme-light {
  --iress-select-margin-top: var(--iress-spacing-xxs);
  --iress-select-margin-bottom: var(--iress-spacing-xxs);
  --iress-select-border-size: var(--iress-border-sm);
  --iress-select-border-color: var(--iress-border-color--form-control);
  --iress-select-border-color-focus: var(--iress-global-focus-color);
  --iress-select-border-color-invalid: var(--iress-global-danger);
  --iress-select-border-color-invalid-focus: var(--iress-global-danger);
  --iress-select-box-shadow-focus: var(--iress-global-focus);
  --iress-select-border-radius: var(--iress-border-radius-sm);
  --iress-select-font-size: inherit;
  --iress-select-color: var(--iress-global-text-color);
  --iress-select-color-focus: var(--iress-global-text-color);
  --iress-select-placeholder-color: var(--iress-global-text-color--muted);
  --iress-select-padding-x: var(--iress-spacing-xs);
  --iress-select-padding-y: var(--iress-spacing-xs);
  --iress-select-background-color: var(--iress-color-white);
  --iress-select-background-color-focus: var(--iress-color-white);
  --iress-select-option-color: var(--iress-global-text-color);
}

.iress-theme-light {
  --iress-radio-group-inline-spacing: var(--iress-spacing-lg);
}





html {
  box-sizing: border-box;
  font-size: 0.875rem;
  font-size: var(--iress-global-font-size, 0.875rem);
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  font-family: var(--iress-global-font-family, sans-serif);
  color: #333;
  color: var(--iress-global-text-color, #333);
  font-weight: normal;
  font-weight: var(--iress-global-font-weight, normal);
  background-color: #fff;
  background-color: var(--iress-global-background-color, #fff);
  line-height: 1.5;
  line-height: var(--iress-global-line-height, 1.5);
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p,
dl,
blockquote {
  margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #333;
  color: var(--iress-global-heading-color, #333);
  font-family: sans-serif;
  font-family: var(--iress-global-heading-font-family, sans-serif);
  font-weight: bold;
  font-weight: var(--iress-global-heading-font-weight, bold);
  margin-top: 1rem;
  margin-top: var(--iress-global-heading-margin-top, 1rem);
  margin-bottom: 1rem;
  margin-bottom: var(--iress-global-heading-margin-bottom, 1rem);
}

h1,
.h1 {
  font-size: 3rem;
  font-size: var(--iress-global-h1-font-size, 3rem);
  font-weight: 400;
  font-weight: var(--iress-global-h1-font-weight, 400);
}

h2,
.h2 {
  font-size: 2rem;
  font-size: var(--iress-global-h2-font-size, 2rem);
}

h3,
.h3 {
  font-size: 1.66rem;
  font-size: var(--iress-global-h3-font-size, 1.66rem);
}

h4,
.h4 {
  font-size: 1.33rem;
  font-size: var(--iress-global-h4-font-size, 1.33rem);
}

h5,
.h5 {
  font-size: 1rem;
  font-size: var(--iress-global-h5-font-size, 1rem);
  font-weight: var(--iress-font-weight-base-bold);
}

h6,
.h6 {
  font-size: 1rem;
  font-size: var(--iress-global-h5-font-size, 1rem);
  font-weight: var(--iress-font-weight-base-regular);
  font-style: italic;
}

h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.h6:first-child {
  margin-top: 0;
}

.display-1,
.display-2,
.display-3 {
  font-family: sans-serif;
  font-family: var(--iress-global-display-font-family, sans-serif);
  font-weight: normal;
  font-weight: var(--iress-global-display-font-weight, normal);
  line-height: 1.25;
  line-height: var(--iress-global-display-line-height, 1.25);
  color: #333;
  color: var(--iress-global-display-color, #333);
  margin-top: 0;
  margin-top: var(--iress-global-display-margin-top, 0);
  margin-bottom: 0;
  margin-bottom: var(--iress-global-display-margin-bottom, 0);
}

.display-1 {
  font-size: 3.75rem;
  font-size: var(--iress-global-display1-font-size, 3.75rem);
}

.display-2 {
  font-size: 2.75rem;
  font-size: var(--iress-global-display2-font-size, 2.75rem);
}

.display-3 {
  font-size: 2.25rem;
  font-size: var(--iress-global-display3-font-size, 2.25rem);
}

.lead {
  font-weight: normal;
  font-weight: var(--iress-global-lead-font-weight, normal);
  font-size: 1.5rem;
  font-size: var(--iress-global-lead-font-size, 1.5rem);
  color: #333;
  color: var(--iress-global-lead-color, #333);
}

.text-success {
  color: #390;
  color: var(--iress-global-success, #390);
}

.text-warning {
  color: #c63;
  color: var(--iress-global-warning, #c63);
}

.text-danger {
  color: #f00;
  color: var(--iress-global-danger, #f00);
}

caption,
.caption,
cite {
  font-weight: normal;
  font-weight: var(--iress-global-caption-font-weight, normal);
  font-size: 0.875rem;
  font-size: var(--iress-global-caption-font-size, 0.875rem);
  color: #666;
  color: var(--iress-global-caption-color, #666);
}

a {
  color: var(--iress-global-link-color);
  -webkit-text-decoration: var(--iress-global-link-text-decoration);
          text-decoration: var(--iress-global-link-text-decoration);
  font-weight: var(--iress-global-link-font-weight);
}

a:hover,
a:focus {
  color: var(--iress-global-link-color-hover);
  -webkit-text-decoration: var(--iress-global-link-text-decoration-hover);
          text-decoration: var(--iress-global-link-text-decoration-hover);
}
a:focus {
  outline: none;
  box-shadow: var(--iress-global-focus);
}
a:active,
.link:active {
  color: var(--iress-global-link-color-active);
  -webkit-text-decoration: var(--iress-global-link-text-decoration-active);
          text-decoration: var(--iress-global-link-text-decoration-active);
}

code {
  display: inline-block;
  font-family: var(--iress-global-code-font-family);
  font-size: var(--iress-global-code-font-size);
  color: var(--iress-global-code-color);
  background-color: var(--iress-global-code-background-color);
  border: var(--iress-global-code-border);
  border-color: var(--iress-global-code-border-color);
  line-height: var(--iress-global-code-line-height);
  padding: 1px;
}

pre {
  margin-top: 0;
}

blockquote {
  position: relative;
  padding-left: var(--iress-spacing-xl);
  margin-left: 0;
  font-style: italic;
}

blockquote::after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: var(--iress-spacing-xxs);
  background-color: var(--iress-color-purple);
}

pre > code {
  position: relative;
  display: block;
  overflow: auto;
  padding: var(--iress-spacing-md) var(--iress-spacing-md)
    var(--iress-spacing-md) var(--iress-spacing-xl);
  border: var(--iress-global-code-border);
  border-color: transparent;
}

hr {
  border: 1px solid var(--iress-border-color--standard);
}

.iress-stack,
.iress-stack > *,
.iress-stack > [data-wrapper-children] > * {
  display: block !important;
  margin-top: 0 !important;
}

.iress-stack > *:not(.iress-inline),
.iress-stack > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0 !important;
}

.iress-stack > *:not(.iress-inline):last-child,
.iress-stack > [data-wrapper-children] > *:not(.iress-inline):last-child {
  margin-bottom: 0 !important;
}

.iress-stack--none > *:not(.iress-inline),
.iress-stack--none > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0 !important;
}

.iress-stack--xs > *:not(.iress-inline),
.iress-stack--xs > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0.125rem !important;
  margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
}

.iress-stack--sm > *:not(.iress-inline),
.iress-stack--sm > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0.25rem !important;
  margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
}

.iress-stack--md > *:not(.iress-inline),
.iress-stack--md > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 0.5rem !important;
  margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
}

.iress-stack--lg > *:not(.iress-inline),
.iress-stack--lg > [data-wrapper-children] *:not(.iress-inline) {
  margin-bottom: 1rem !important;
  margin-bottom: var(--iress-spacing-md, 1rem) !important;
}

.iress-stack--xl > *:not(.iress-inline),
.iress-stack--xl > [data-wrapper-children] > *:not(.iress-inline) {
  margin-bottom: 1.5rem !important;
  margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
}

@media only screen and (min-width: 576px) {
  .iress-stack-sm--none > *:not(.iress-inline),
  .iress-stack-sm--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-sm--xs > *:not(.iress-inline),
  .iress-stack-sm--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.125rem !important;
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-sm--sm > *:not(.iress-inline),
  .iress-stack-sm--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.25rem !important;
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-sm--md > *:not(.iress-inline),
  .iress-stack-sm--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.5rem !important;
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-sm--lg > *:not(.iress-inline),
  .iress-stack-sm--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1rem !important;
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-sm--xl > *:not(.iress-inline),
  .iress-stack-sm--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1.5rem !important;
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 768px) {
  .iress-stack-md--none > *:not(.iress-inline),
  .iress-stack-md--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-md--xs > *:not(.iress-inline),
  .iress-stack-md--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.125rem !important;
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-md--sm > *:not(.iress-inline),
  .iress-stack-md--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.25rem !important;
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-md--md > *:not(.iress-inline),
  .iress-stack-md--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.5rem !important;
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-md--lg > *:not(.iress-inline),
  .iress-stack-md--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1rem !important;
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-md--xl > *:not(.iress-inline),
  .iress-stack-md--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1.5rem !important;
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .iress-stack-lg--none > *:not(.iress-inline),
  .iress-stack-lg--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-lg--xs > *:not(.iress-inline),
  .iress-stack-lg--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.125rem !important;
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-lg--sm > *:not(.iress-inline),
  .iress-stack-lg--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.25rem !important;
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-lg--md > *:not(.iress-inline),
  .iress-stack-lg--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.5rem !important;
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-lg--lg > *:not(.iress-inline),
  .iress-stack-lg--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1rem !important;
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-lg--xl > *:not(.iress-inline),
  .iress-stack-lg--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1.5rem !important;
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .iress-stack-xl--none > *:not(.iress-inline),
  .iress-stack-xl--none > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0 !important;
  }

  .iress-stack-xl--xs > *:not(.iress-inline),
  .iress-stack-xl--xs > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.125rem !important;
    margin-bottom: var(--iress-spacing-xxs, 0.125rem) !important;
  }

  .iress-stack-xl--sm > *:not(.iress-inline),
  .iress-stack-xl--sm > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.25rem !important;
    margin-bottom: var(--iress-spacing-xs, 0.25rem) !important;
  }

  .iress-stack-xl--md > *:not(.iress-inline),
  .iress-stack-xl--md > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 0.5rem !important;
    margin-bottom: var(--iress-spacing-sm, 0.5rem) !important;
  }

  .iress-stack-xl--lg > *:not(.iress-inline),
  .iress-stack-xl--lg > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1rem !important;
    margin-bottom: var(--iress-spacing-md, 1rem) !important;
  }

  .iress-stack-xl--xl > *:not(.iress-inline),
  .iress-stack-xl--xl > [data-wrapper-children] > *:not(.iress-inline) {
    margin-bottom: 1.5rem !important;
    margin-bottom: var(--iress-spacing-lg, 1.5rem) !important;
  }
}

.iress-inline {
  display: flex;
  flex-wrap: wrap;
}

.iress-inline--left {
  justify-content: flex-start;
}

.iress-inline--right {
  justify-content: flex-end;
}

.iress-inline--center {
  justify-content: center;
}

.iress-inline > * {
  margin-top: 0 !important;
  display: inline-block !important;
}

.iress-inline--left.iress-inline--none > *,
.iress-inline--center.iress-inline--none > *,
.iress-inline--right.iress-inline--none > * {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.iress-inline--xs {
  margin-bottom: var(--iress-spacing-neg-xxs);
}

.iress-inline--xs.iress-inline--left > * {
  margin-right: var(--iress-spacing-xxs) !important;
  margin-left: 0 !important;
}

.iress-inline--xs.iress-inline--right > * {
  margin-left: var(--iress-spacing-xxs) !important;
  margin-right: 0 !important;
}

.iress-inline--xs.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
  margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
}

.iress-inline--xs > * {
  margin-bottom: var(--iress-spacing-xxs) !important;
}

.iress-inline--sm {
  margin-bottom: var(--iress-spacing-neg-xs);
}

.iress-inline--sm > * {
  margin-bottom: var(--iress-spacing-xs) !important;
}

.iress-inline--sm.iress-inline--left > * {
  margin-right: var(--iress-spacing-xs) !important;
  margin-left: 0 !important;
}

.iress-inline--sm.iress-inline--right > * {
  margin-left: var(--iress-spacing-xs) !important;
  margin-right: 0 !important;
}

.iress-inline--sm.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-xs) / 2) !important;
  margin-right: calc(var(--iress-spacing-xs) / 2) !important;
}

.iress-inline--md {
  margin-bottom: var(--iress-spacing-neg-sm);
}

.iress-inline--md > * {
  margin-bottom: var(--iress-spacing-sm) !important;
}

.iress-inline--md.iress-inline--left > * {
  margin-right: var(--iress-spacing-sm) !important;
  margin-left: 0 !important;
}

.iress-inline--md.iress-inline--right > * {
  margin-left: var(--iress-spacing-sm) !important;
  margin-right: 0 !important;
}

.iress-inline--md.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-sm) / 2) !important;
  margin-right: calc(var(--iress-spacing-sm) / 2) !important;
}

.iress-inline--lg {
  margin-bottom: var(--iress-spacing-neg-md) !important;
}

.iress-inline--lg > * {
  margin-bottom: var(--iress-spacing-md) !important;
}

.iress-inline--lg.iress-inline--left > * {
  margin-right: var(--iress-spacing-md) !important;
  margin-left: 0 !important;
}

.iress-inline--lg.iress-inline--right > * {
  margin-left: var(--iress-spacing-md) !important;
  margin-right: 0 !important;
}

.iress-inline--lg.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-md) / 2) !important;
  margin-right: calc(var(--iress-spacing-md) / 2) !important;
}

.iress-inline--xl {
  margin-bottom: var(--iress-spacing-neg-lg);
}

.iress-inline--xl > * {
  margin-bottom: var(--iress-spacing-lg) !important;
}

.iress-inline--xl.iress-inline--left > * {
  margin-right: var(--iress-spacing-lg) !important;
  margin-left: 0 !important;
}

.iress-inline--xl.iress-inline--right > * {
  margin-left: var(--iress-spacing-lg) !important;
  margin-right: 0 !important;
}

.iress-inline--xl.iress-inline--center > * {
  margin-left: calc(var(--iress-spacing-lg) / 2) !important;
  margin-right: calc(var(--iress-spacing-lg) / 2) !important;
}

@media only screen and (min-width: 576px) {
  .iress-inline--left.iress-inline-sm--none > *,
  .iress-inline--center.iress-inline-sm--none
    > *
    .iress-inline--right.iress-inline-sm--none
    > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-sm--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-sm--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-sm--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-sm--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-sm--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-sm--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-sm--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-sm--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-sm--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-sm--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-sm--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-sm--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-sm--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-sm--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-sm--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-sm--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 768px) {
  .iress-inline--left.iress-inline-md--none > *,
  .iress-inline--center.iress-inline-md--none > *,
  .iress-inline--right.iress-inline-md--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-md--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-md--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-md--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-md--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-md--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-md--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-md--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-md--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-md--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-md--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-md--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-md--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-md--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-md--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-md--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-md--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 1024px) {
  .iress-inline--left.iress-inline-lg--none > *,
  .iress-inline--center.iress-inline-lg--none > *,
  .iress-inline--right.iress-inline-lg--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-lg--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-lg--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-lg--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-lg--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-lg--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-lg--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-lg--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-lg--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-lg--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-lg--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-lg--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-lg--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-lg--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-lg--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-lg--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-lg--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

@media only screen and (min-width: 1200px) {
  .iress-inline--left.iress-inline-xl--none > *,
  .iress-inline--center.iress-inline-xl--none > *,
  .iress-inline--right.iress-inline-xl--none > * {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xs {
    margin-bottom: var(--iress-spacing-neg-xxs);
  }

  .iress-inline-xl--xs.iress-inline--left > * {
    margin-right: var(--iress-spacing-xxs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--xs.iress-inline--right > * {
    margin-left: var(--iress-spacing-xxs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xs.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xxs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xxs) / 2) !important;
  }

  .iress-inline-xl--xs > * {
    margin-bottom: var(--iress-spacing-xxs) !important;
  }

  .iress-inline-xl--sm {
    margin-bottom: var(--iress-spacing-neg-xs);
  }

  .iress-inline-xl--sm > * {
    margin-bottom: var(--iress-spacing-xs) !important;
  }

  .iress-inline-xl--sm.iress-inline--left > * {
    margin-right: var(--iress-spacing-xs) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--sm.iress-inline--right > * {
    margin-left: var(--iress-spacing-xs) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--sm.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-xs) / 2) !important;
    margin-right: calc(var(--iress-spacing-xs) / 2) !important;
  }

  .iress-inline-xl--md {
    margin-bottom: var(--iress-spacing-neg-sm);
  }

  .iress-inline-xl--md > * {
    margin-bottom: var(--iress-spacing-sm) !important;
  }

  .iress-inline-xl--md.iress-inline--left > * {
    margin-right: var(--iress-spacing-sm) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--md.iress-inline--right > * {
    margin-left: var(--iress-spacing-sm) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--md.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-sm) / 2) !important;
    margin-right: calc(var(--iress-spacing-sm) / 2) !important;
  }

  .iress-inline-xl--lg {
    margin-bottom: var(--iress-spacing-neg-md) !important;
  }

  .iress-inline-xl--lg > * {
    margin-bottom: var(--iress-spacing-md) !important;
  }

  .iress-inline-xl--lg.iress-inline--left > * {
    margin-right: var(--iress-spacing-md) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--lg.iress-inline--right > * {
    margin-left: var(--iress-spacing-md) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--lg.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-md) / 2) !important;
    margin-right: calc(var(--iress-spacing-md) / 2) !important;
  }

  .iress-inline-xl--xl {
    margin-bottom: var(--iress-spacing-neg-lg);
  }

  .iress-inline-xl--xl > * {
    margin-bottom: var(--iress-spacing-lg) !important;
  }

  .iress-inline-xl--xl.iress-inline--left > * {
    margin-right: var(--iress-spacing-lg) !important;
    margin-left: 0 !important;
  }

  .iress-inline-xl--xl.iress-inline--right > * {
    margin-left: var(--iress-spacing-lg) !important;
    margin-right: 0 !important;
  }

  .iress-inline-xl--xl.iress-inline--center > * {
    margin-left: calc(var(--iress-spacing-lg) / 2) !important;
    margin-right: calc(var(--iress-spacing-lg) / 2) !important;
  }
}

.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #006edc;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.react-date-picker {
  display: inline-flex;
  position: relative;
}
.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  box-sizing: border-box;
}
.react-date-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid gray;
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) +  0.54em * 8  +  0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
  box-sizing: content-box;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px +  0.54em);
}
.react-date-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}
.react-date-picker__button:enabled {
  cursor: pointer;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d;
}
.react-date-picker__button svg {
  display: inherit;
}
.react-date-picker__calendar {
  width: 350px;
  max-width: 100vw;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
}
.react-date-picker__calendar--closed {
  display: none;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin;
}

